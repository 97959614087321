import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material"

export const PatentsPopup = ({open, handleClose}) => {
    return (
      <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogActions>
        <div className="w-full flex justify-between px-4">
          <h5>Charlee Ai Patents</h5>
          <Button onClick={handleClose} autoFocus>
            close
          </Button>
        </div>
      </DialogActions>
      <DialogContent>
      <iframe className="w-full h-full" src="/CharleeAi-IP-info.pdf"></iframe>
      </DialogContent>
    </Dialog>
    )
}