export const Post22441 = () => {
    return (
        <div className="w-full text-left">
            <div className="w-full min-h-[400px] md:min-h-[500px] rounded-xl" style={{background: `url(/blog-photos/PATH-TO-IMPROVING-FIRST-NOTICE-OF-LOSS-FNOL-WITH-AI-web-image1.webp)`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}></div>
            
            <p className="mt-4">‘It is always at the start that the momentum in sport is created,’ goes an often repeated saying. The beginning and pace at which a sport is set is where champions are made, championships are won, and careers get going. Track and field enthusiasts know how positive outcomes get a sprinter out of the blocks when the starter’s pistol shot is fired or how a swimmer’s lead in a race is set based on the kind of edge they can establish during the first lap. In claims management, the first few hours after an accident, a calamity, or loss provide the vital period required to gather data, establish facts, and chalk the incident’s trajectory, setting the tone for the entire claims management cycle to unfold. This phase, called The First Notice of Loss (FNOL), must be flawless in gathering data and establishing what and how the incident happened, communicating the next steps to the customer, and assessing all possible exposures or applicable coverages.</p>
            
            <h3 className="mt-4 text-[22px] font-bold">THE IMPORTANCE OF FNOL</h3>
            <p className="mt-4">The first time the claimant informs the insurer of a loss, is when it is considered ‘making a claim.’ This is when the lifecycle of a formal claim begins and is very crucial both for the claimant and insurer. In the case of the former, it establishes the result of the policy the claimant has paid for and whether his claim will be dealt with swiftly, immediately, and within an adequate response time. In the case of the latter, it is a chance for the insurer to prove or validate their reputation in handling the claims quickly, ensuring a good resolution and justified settlement.</p>
            <p className="mt-4">Registering an FNOL requires the insurer to submit critical information – policy number, date and time of the loss, incident location, police report number, a personal account of how the incident occurred, and insurance information of the other party where required. In the case of a car accident, details given to an FNOL representative will include all the above, as well as questions about the nature and severity of the damage, history of the claimant, other driver’s testimony, police reports, witness accounts, medical report if any, observed damage to vehicles, etc.</p>
            
            <div className="w-full min-h-[400px] md:min-h-[500px] rounded-xl" style={{background: `url(/blog-photos/PATH-TO-IMPROVING-FIRST-NOTICE-OF-LOSS-FNOL-WITH-AI-web-image2.webp)`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}></div>
            
            <h3 className="mt-4 text-[22px] font-bold">ARTIFICIAL INTELLIGENCE INSIGHTS – THE GAME CHANGER</h3>
            <p className="mt-4">In any incident report and subsequent investigation, the basics must be addressed: Who? What? When? Why? Where? And How? For the FNOL to be complete, this essential data must be registered and admitted into the claims management system accurately and efficiently. This is where AI’s intervention phase has come to achieve vast significance. AI’s pattern recognition software and ability to derive deep insights based on past claims patterns from structured and unstructured data – claims notes, documents, third-party PDFs, and news articles – is helping configure unique insights that were hitherto often missed during claims registration. The gathering of such information is helping insurers predict claims trajectories in advance, allowing them to monitor the pulse of the allegations and the direction they are going in, and, from a business perspective, helping in better reserves management, risk assessment, and observation of annual trends.</p>
            
            <h3 className="mt-4 text-[22px] font-bold">IN CONVERSATION WITH BRAD METZGER, CLAIMS DIRECTOR</h3>
            <p className="mt-4">Brad Metzger, having worked in the P&amp;C Industry for several years across Claims Operations, Claims Strategy, and Claims IT, has first-hand experience of the impact of AI during FNOL. Having observed the insurance industry for over two decades and observing the criticality of data insights by AI intervention in claims management, he sheds light on why FNOL standardization using NLP-led AI engines can make the claims management process sharper, better, and more intelligent.</p>
            <p className="mt-4"><strong>Question: Brad, how important is the standardization of FNOL, and how can AI impact it during the claims process?</strong></p>
            <p className="mt-4">The FNOL standardization is an essential part of claims management and must include the ability to connect to the insurance company virtually. Connecting IoT devices, such as crash detection and connected homes, makes the whole process seamless and sharp. This is what drives the value.</p>
            <p className="mt-4"><strong>Question: How do quick insights during FNOL, impact insurers and policyholders?</strong></p>
            <p className="mt-4">The FNOL is when the first insights are gathered from the insured and people involved in the incident. Many seen and unseen insights can impact the trajectory of the claims in the future, ‘if and when’ picked up at this stage. Getting the loss details immediately after the loss helps insurers triage better based on customer needs. The earlier the data is gathered, the better it becomes to understand how insurers need to respond to the claimant, all in an effort to reduce the human trauma associated with the loss. This impacts both expenses and claims severity, especially in high-risk claims.</p>
            <p className="mt-4"><strong>Question: What interventions can AI do at this stage to speed up the claims process or identify critical data that may have been missed?</strong></p>
            <p className="mt-4">Once the claims are registered, the insurer’s goal is to quickly meet and exceed the expectations of all constituents in the claims process, as the adjudication process has already started. This will result in happy customers, smiling faces, and mitigation of unnecessary indemnity and expense costs. More importantly, AI intervention can predict claims severity and the likelihood of social inflation factors such as attorney representation/litigation and get a jump start on posting accurate reserves.</p>
            
            <div className="w-full min-h-[400px] md:min-h-[500px] rounded-xl" style={{background: `url(/blog-photos/PATH-TO-IMPROVING-FIRST-NOTICE-OF-LOSS-FNOL-WITH-AI-web-image3.webp)`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}></div>
            
            <h3 className="mt-4 text-[22px] font-bold">CONCLUSION</h3>
            <p className="mt-4">Any delay or failure to gather relevant insights during the FNOL can increase claims cycle times and expenses, indemnity severity and also negatively impact the overall customer experience. Getting as much information in this stage as possible will help ease several future processes, reduce cycle times, help better risk assessments, and predict claims severity well in advance.</p>
            <p className="mt-4">Charlee provides an NLP-driven claims summary, insights and predictions. This identifies gaps in information gathered, high priority claims for intelligent routing and assignment including workflow prioritization. An AI engine like Charlee is pre-trained for personal, automobile, and commercial insurance lines, picking insights from structured and unstructured data during FNOL and aiding claims analysts and managers with strategic insights and quantitative predictions to make real-time decisions while understanding and foreseeing user behavior patterns.</p>
            <p className="mt-4">Charlee.ai predicts litigation 90-120 days in advance with approximately 85% accuracy, starting at the First Notice of Loss (FNOL).</p>
            
            <h3 className="mt-4 text-[22px] font-bold">About the Authors</h3>
            <p className="mt-4"><strong>Brad Metzger</strong></p>
            <p className="mt-4"><em>Claims Director</em></p>
            <p className="mt-4"><strong>Dr. Charmaine Kenita</strong></p>
            <p className="mt-4"><em>Technology and Business Writer</em></p>
        </div>
    );
};
