export const Post20376 = () => {
    return (
        <div class="w-full text-left">
            <div className="w-full min-h-[400px] md:min-h-[500px] rounded-xl" style={{background: `url(/blog-photos/Managing-Slip-and-Fall-Claims-v2.jpg)`, backgroundSize: 'cover', backgroundPosition: 'center' }}></div>
            <p className="mt-4">The Infinilytics Team and Charlee™, the first Insurance Insights Engine, have been very busy this year working with several insurance companies on commercial general liability claims and risk management issues. Our Innovation Team has noted several consistent problems in processing and negotiating slip-and-fall claims and wanted to share some thought leadership considerations for claim examiners, managers, and executives.</p>
            <p className="mt-4">The most common type of claim in commercial general liability insurance is the slip-and-fall. Of course, accidents happen, but the adage in risk management, “If it’s predictable, it’s preventable,” must be followed by the business owner and the insurance carrier writing the policy for indemnity coverage. Loss prevention recommendations by Risk Management must be taken seriously by your customers to reduce the frequency, severity, and litigation related to slip-and-fall and trip-and-fall claims. Here are some quick facts on the common types of injuries sustained in these claims :</p>
            <p className="mt-4">• More than one million people go to the emergency room every year for a slip and fall accident, which equals 2,000 people a day in the United States alone.</p>
            <p className="mt-4">• Slip and fall claims cost general liability insurers an average of $30,000 per settlement.</p>
            <p className="mt-4">• Commercial insurer CNA reported that between 2007 and 2012, the average cost of a traumatic brain injury in a liability claim was $269,643.00</p>
            <p className="mt-4">More statistics :</p>
            <p className="mt-4">• According to the Centers for Disease Control and Prevention, the average hospital cost of a slip and fall is more than $30,000.00</p>
            <p className="mt-4">• High-end commercial properties are a particular risk because of the focus on heritage properties, original design materials, and glossy floor finishes.</p>
            <p className="mt-4">Risk management strategies are usually in place to prevent falls, but accidents do happen. The right A.I. solution for your claims and underwriting team is the first step in crafting a severity and litigation strategy.</p>
            
            <div className="mt-8 w-full min-h-[400px] md:min-h-[500px] rounded-xl" style={{background: `url(https://charlee.ai/wp-content/uploads/2021/09/Managing-Slip-and-Fall-Claims-Severity-with-Artificial-Intelligence-v2.jpg)`, backgroundSize: 'cover', backgroundPosition: 'center' }}></div>
            <h3 class="mt-4 text-[22px] font-bold">Charlatans Versus Legitimate Claims with Serious Injuries</h3>
            <p className="mt-4">People who intentionally stage slipping and falling in a business (or somewhere on the property) are nothing new. We have seen countless newscast stories depicting people pouring liquids on the floor and then lying down feigning injuries. Many of these folks are caught by video camera surveillance. The astute employee follows their policies and protocols in reporting these incidents to the appropriate authorities and their insurance company. Unfortunately, however, some people try to get away with sophisticated insurance fraud schemes. One example is where attorneys systematically used homeless people and people suffering from substance abuse to stage slip-and-fall incidents, preying on businesses and their insurance carriers to the tune of $31 million before being caught.</p>
            <p className="mt-4">Insurance companies can mitigate this type of behavior with robust artificial intelligence, machine learning, and natural language processing in the underwriting and claims process, starting at the First Notice of Loss (FNOL). As a result, legitimate claims involving severe injuries can be distinguished from questionable ones, saving your company money.</p>
            <h3 class="mt-4 text-[22px] font-bold" >Introducing Charlee™ – The First Insurance Insights Engine</h3>
            <p className="mt-4">
                <span >The Infinilytics team is multidisciplinary with deep experience in insurance, technology, data science, and government; specifically, law enforcement (white-collar crime expertise) and legislative and regulatory best practices.&nbsp; The combination of these fields led to the creation of the first-ever Insurance Insights Engine – Charlee™.&nbsp; Charlee™ (patent pending) is an artificial intelligence solution with machine learning and natural language processing with over 40,000 prebuilt insights.&nbsp; One powerful and unique feature of Charlee™ is that through unstructured text analysis, topics and patterns (behaviors) are identified, analyzed, and presented in an intuitive interface allowing the user to identify claims trending towards possible high severity and litigation quickly. In addition, the Charlee™ solution is a cloud-based software as a service (SaaS) that can easily integrate via an automated program interface (API) into your claims and underwriting process. Infinilytics’ customers start with a proof-of-concept project for up to six weeks with their claims and underwriting data. Upon completion and acceptance, the customer can immediately begin using Charlee™ while additional and custom requirements are created and deployed.&nbsp; In short, you can have an A.I. solution up and running within a few weeks, not one to two years.&nbsp;</span>
            </p>
            <p className="mt-4">
                [1] Gordon Graham, Lexipol, <a href="https://www.lexipol.com/">https://www.lexipol.com</a>
            </p>
            <p className="mt-4">[2] Risk &amp; Insurance (May 2018, p. 32)</p>
            <p className="mt-4">[3] &nbsp;https://safetydirectamerica.com/whats-the-cost-of-a-slip-and-fall-on-a-commercial-property/</p>

            <div className="mt-8 w-full min-h-[400px] md:min-h-[500px] rounded-xl" style={{background: `url(https://charlee.ai/wp-content/uploads/2021/09/Managing-Slip-and-Fall-Claims-Severity-with-Artificial-Intelligence.jpg)`, backgroundSize: 'cover', backgroundPosition: 'center' }}></div>
            <h3 class="mt-4 text-[22px] font-bold" >Leverage Charlee™ at the First Notice of Loss to Mitigate Severity and Litigation!</h3>
            <p className="mt-4">People who intentionally stage slipping and falling in a business (or somewhere on the property) are nothing new. We have seen countless newscast stories depicting people pouring liquids on the floor and then lying down feigning injuries. Many of these folks are caught by video camera surveillance. The astute employee follows their policies and protocols in reporting these incidents to the appropriate authorities and their insurance company. Unfortunately, however, some people try to get away with sophisticated insurance fraud schemes. One example is where attorneys systematically used homeless people and people suffering from substance abuse to stage slip-and-fall incidents, preying on businesses and their insurance carriers to the tune of $31 million before being caught.</p>
            <p className="mt-4">Insurance companies can mitigate this type of behavior with robust artificial intelligence, machine learning, and natural language processing in the underwriting and claims process, starting at the First Notice of Loss (FNOL). As a result, legitimate claims involving severe injuries can be distinguished from questionable ones, saving your company money.</p>
            <h3 class="mt-4 text-[22px] font-bold" >Use Case</h3>
            <p className="mt-4">The Infinilytics client needed better insights on their fast-food restaurants’ policyholders as claim frequency and severity were high. Charlee™ quickly analyzed past claims for topics and events that lead to increased severity and litigation. With Charlee™ examining thousands of claim notes (unstructured text), many issues and entities were identified in the designated high severity claims. Additionally, the cause of the loss, combined with the reported injuries and the subsequent medical treatments with healthcare professionals, exposed potential areas where the Claims Examiner and Manager can take the appropriate action steps to reduce the severity and save costs.</p>
            <p className="mt-4">Charlee™ does not stop analyzing data at the FNOL. Instead, Charlee™ constantly analyzes prior claims, incident reports, witness statements, emergency medical service reports, hospital reports, and any documentation regarding the insured property’s maintenance. Charlee™ will point out red flags for suspicious behavior patterns and make quality control alerts (industry best practices) for the Claim Examiner to review and consider while investigating the claim.</p>
            <p className="mt-4">As the claim progresses, insights are identified for high severity, best practices, and pre-litigation patterns. Charlee™ learns from the past, leverages the insights into current claims, and monitors the actions of the Claims Examiner to understand further, which brings more insights into the process. In other words, Charlee™ is the ultimate ‘cyber-supervisor’ and assistant for the claims team! The benefit of Charlee™ is that you will meet your customer’s needs and lower claims costs, improve the efficiency of the claim investigation, and reduce the severity.</p>
            <p className="mt-4">
            <strong>Getting Started</strong>
            </p>
            <p className="mt-4">Call us today to speak with a representative and learn how Charlee™ can help you. In a few weeks, you can have the first Insurance Insights Engine deployed for your claims and underwriting team and quickly realize cost savings in your allocated loss adjustment expenses, indemnity, and litigation.</p>
            <p className="mt-4">
                [4] <a href="https://www.reuters.com/legal/government/two-ny-lawyers-accused-31-mln-insurance-scam-2021-08-25/">https://www.reuters.com/legal/government/two-ny-lawyers-accused-31-mln-insurance-scam-2021-08-2</a>
            </p>
        </div>
    )
}