import axios from 'axios';
import Mailjet from 'node-mailjet';
import emailjs from '@emailjs/browser';

const emailPublicKey = "byEn0SE74t8G0f_Co";
const emailServiceId = 'service_83pcnm7';
const emailContactUsId = 'template_jkhxxks';
const emailTemplateId2 = 'template_ktgzdgf';

const mailApiKey = 'ce50e9702de44f50a6d16d1461c91b86';
const mailSecretyKey = '43105879661fb99426026c7dce24a2d9';
const host = '72.167.46.56';
// const host = 'localhost';
const backendPath = `http://${host}:4000/email/send`;
const requestEmailPath = `http://${host}:4000/email/send-request-demo`
const learnEmailPath = `http://${host}:4000/email/send-learn-mode`
const contactEmailPath = `http://${host}:4000/email/send-contact-us`
// const mailjet = Mailjet.apiConnect(
//     mailApiKey,
//     mailSecretyKey
// );
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

export const googleCaptchaValue = '6Lej8fQqAAAAAHC6hbi6lSoi6kgx4yZKfRlbSFAs';

export const sendEmail = async (fromMail, fromName) => {
    console.log(fromMail, fromName);
    axios.post(backendPath, {
      to: fromMail,
      toname: fromName,
      subject: "Request a demo today",
      text: "Sign up for a personalized demo today and see how Charlee can help you stay ahead of potential fraud, mitigate losses, and optimize efficiency.",
      html: "<h1>Request a demo today</h1><p>Sign up for a personalized demo today and see how Charlee can help you stay ahead of potential fraud, mitigate losses, and optimize efficiency.</p>"
    })
}

export const sendContactEmail = async (fromMail, fromName, contactData) => {
  emailjs.send(emailServiceId, emailContactUsId, contactData, {
        publicKey: emailPublicKey,
      })
      .then(
        () => {
          console.log('SUCCESS!');
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
  // axios.post(contactEmailPath, {
  //   to: fromMail,
  //   toname: fromName,
  //   variables: contactData
  // })
}

export const sendLearnEmail = async (fromMail, fromName, learnData) => {
  const templateData= {
    subject: "Learn More",
    emailtext: `he ${learnData.studymail} wants to learn more,`,
    text: ``,
    requestmail: learnData.studymail
  }
  emailjs.send(emailServiceId, emailTemplateId2, templateData, {
    publicKey: emailPublicKey,
  })
  .then(
    () => {
      console.log('SUCCESS!');
    },
    (error) => {
      console.log('FAILED...', error.text);
    },
  );
  // axios.post(learnEmailPath, {
  //   to: fromMail,
  //   toname: fromName,
  //   variables: learnData
  // })
}

export const sendRequestEmail = async (fromMail, fromName, requestData) => {
  const templateData= {
    subject: "Request a demo today",
    emailtext: `The ${requestData.fullname} is requested the Demo now,`,
    text: `It is from the ${requestData.requestmail}`,
    requestmail: requestData.requestmail
  }
  emailjs.send(emailServiceId, emailTemplateId2, templateData, {
    publicKey: emailPublicKey,
  })
  .then(
    () => {
      console.log('SUCCESS!');
    },
    (error) => {
      console.log('FAILED...', error.text);
    },
  );
}