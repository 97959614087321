import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom"
import { HomeHeader } from "../../components/headers/HomeHeader";
import podCastItems from "../../providers/datas/podcasts.json";
import { Box, Container } from "@mui/material";

export const PodcastContent = () => {
  const navigate = useNavigate();
  const searchParams = useParams();
  const [podCastData, setPodCastData] = useState();
  const [relatedPodCasts, setRelatedPodCasts] = useState([]);

  useEffect(() => {
    loadPodCastData(searchParams['*']);
  }, [searchParams])

  const renderBlogDate = (blogItem) => {
    const dateStr = new Date(blogItem.postDate);
    return dateStr.toDateString();
  }

  const loadPodCastData = (podCastId) => {
    console.log('params data', podCastId);
    const podCastObj = podCastItems.find(item => item.postName == podCastId);
    setPodCastData(podCastObj);

  }

  return (
    <div className="w-full overflow-hidden bg-[#F3F4F4]">
      <HomeHeader headerColor={'#F9F9F9'} textColor={'#021744'}/>
      <div className="news-page bg-[#F9F9F9]">
        <Box className="w-full mt-20">
          <Container maxWidth="md">
            <Box className="w-full pt-14">
              <h2 className="text-[65px] leading-[71.5px] font-medium">
                {podCastData?.title}
              </h2>
              <Box className="w-full text-[#0099B0] " paddingY={1} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                <span className="px-3">
                  3 minute read
                </span>
                {podCastData && <span className="px-3 border-l border-l-[#0099B0]">
                  {renderBlogDate(podCastData)}
                </span>}
                <span className="px-3 border-l border-l-[#0099B0]">
                  By Charlee AI
                </span>
              </Box>
            </Box>
            
            <div className="w-full min-h-[400px] md:min-h-[500px] rounded-xl mt-12" >
                <iframe title="Does Regulation Stand in the Way of Innovation? AI in Insurance"
                 src={podCastData?.contentMedia} 
                 width="800" height="450" allowfullscreen="" 
                 allow="autoplay; fullscreen" id="player_1" data-lf-form-tracking-inspected-ywvko4xvj5z8z6bj="true" 
                 data-lf-yt-playback-inspected-ywvko4xvj5z8z6bj="true" data-lf-vimeo-playback-inspected-ywvko4xvj5z8z6bj="true" 
                 data-gtm-yt-inspected-8="true"></iframe>
            </div>
          </Container>
        </Box>
      </div>
    </div>
  )
}